<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增区域</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <!-- tree-table表格组件 -->
      <tree-table style="overflow-y: scroll;height: 95%;border: none" :is-fold="false" :data="areaList" :expand-type="false" :columns="columns" :selection-type="false" :show-row-hover="true" stripe>
        <template slot="opt" slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="修改" placement="top">
            <el-button type="warning" icon="el-icon-edit" circle size="mini" @click="open('edit', scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteArea(scope.row)"></el-button>
          </el-tooltip>
        </template>
      </tree-table>
    </div>
    <!-- 行政区域弹窗 -->
    <el-dialog :title="optFlag?'添加行政区域':'修改行政区域'" :visible.sync="dialog1" width="30%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="areaForm" ref="areaForm" :rules="rules">
        <el-form-item label="区域名称" prop="areaName" :label-width="formLabelWidth">
          <el-input v-model="areaForm.areaName" autocomplete="off" placeholder="请输入区域名称"></el-input>
        </el-form-item>
        <el-form-item label="区域类型" prop="type" :label-width="formLabelWidth">
          <el-select v-model="areaForm.type" placeholder="请选择区域类型" style="width:100%">
            <el-option v-for="(item,i) in areaSelect" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="父级菜单" prop="parentId" :label-width="formLabelWidth">
          <el-cascader v-model="areaForm.parentIds" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:100%" placeholder="请选择父级菜单"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog1 = false">取 消</el-button>
        <el-button type="primary" @click="addArea" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateArea" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TreeTable from 'vue-table-with-tree-grid'
export default {
  components: {
    TreeTable
  },
  data () {
    return {
      formLabelWidth: '100px',
      dialog1: false, // 行政区域弹窗开关
      areaList: [], // 行政区域列表
      areaSelect: [],
      optFlag: true,
      areaForm: {
        areaName: '',
        type: '',
        parentIds: []
      },
      areaId: null,
      areaFormCopy: {},
      columns: [
        {
          label: '区域名称',
          prop: 'label'
        },
        {
          label: '行政编号',
          prop: 'areaCode'
        },
        {
          label: '操作',
          type: 'template',
          template: 'opt'
        }
      ],
      rules: {
        areaName: [
          { required: true, message: '请输入行政区域名称', trigger: 'blur' },
          { pattern: /(^$)|(^[\u4E00-\u9FA5a-zA-Z0-9]{1,10}$)/, message: '请输入合法的行政区域名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择行政区域类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.initArea()
    this.getAreaType()
    this.areaFormCopy = JSON.stringify(this.areaForm)
  },
  methods: {
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.dialog1 = true
          this.optFlag = true
          break
        case 'edit':
          this.dialog1 = true
          this.$nextTick(() => {
            this.$refs.areaForm.clearValidate()
          })
          this.optFlag = false
          this.areaForm = JSON.parse(JSON.stringify(row))
          this.$set(this.areaForm, 'areaName', row.label)
          this.$set(this.areaForm, 'areaType', row.type)
          this.areaId = row.value
          break
      }
    },
    // 查找行政区域
    async initArea () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        this.areaList = result.data
        this.total = result.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 删除行政区域
    async deleteArea (row) {
      const confirm = await this.$confirm('此操作将删除该行政区域, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/area/del/${row.value}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initArea()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 添加行政区域
    async addArea () {
      this.$refs.areaForm.validate(async valid => {
        if (valid) {
          if (this.areaForm.parentIds.length > 0) {
            this.areaForm.parentId = this.areaForm.parentIds[this.areaForm.parentIds.length - 1]
          }
          const { data: result } = await this.$axios.post('/area/add', this.areaForm)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.dialog1 = false
            this.initArea()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 修改行政区域
    async updateArea () {
      this.$refs.areaForm.validate(async valid => {
        if (valid) {
          if (this.areaForm.parentIds.length > 0) {
            this.areaForm.parentId = this.areaForm.parentIds[this.areaForm.parentIds.length - 1]
          }
          const { data: result } = await this.$axios.put(`/area/edit/${this.areaId}`, this.areaForm)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.dialog1 = false
            this.initArea()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async getAreaType () {
        const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'areaType' } })
      if (result.code === 200) {
        this.areaSelect = result.data
      }
    },
    clear () {
      this.areaForm = JSON.parse(this.areaFormCopy)
      this.$refs.areaForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 10px;
  .content {
    overflow-y: scroll;
  }
}
</style>
